<template>
    <div class="elem-goods-box" :style="{ width: width }">
        <div class="textarea-label-box" @click="onDisplayModelPage">
            <div class="label-box">
                <div class="label-item-box" v-for="(item, idx) in labels" :key="'labelid-' + item.key">
                    <p class="value">{{ item.value }}</p>
                    <div class="delete-btn" @click.stop="onDeleteLabel(item.key, idx)"></div>
                </div>
                <p class="hint" v-if="!labels || labels.length <= 0">{{ place_holder }}</p>
            </div>
            <div class="arrow"></div>
        </div>

        <CompModal ref="comp_model" :title="title">
            <div class="category-base">
                <div class="search-base">
                    <div class="search-box">
                        <div class="icon-box">
                            <Icon type="ios-search" />
                        </div>
                        <p class="name">搜索商品</p>
                    </div>
                </div>
                <div class="con-box">
                    <div :scroll-y="true" class="menu-base" v-if="categorys">
                        <div class="menu-box">
                            <div class="item-box" :class="{ activity: activity_id === '' }" @click="activity_id = ''">
                                <p class="name">全部</p>
                            </div>
                            <div class="item-box" v-for="(item, idx) in categorys" :class="{ activity: activity_id == item.catId }" :key="idx" @click="activity_id = item.catId">
                                <p class="name">{{ item.catName }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="commodity-box" @scroll="onScrollToLower">
                        <div class="cart-card" v-for="item in commoditys[activity_id]" :key="'goodsId-' + item.goodsId" @tap="jump('/apps/suppliershop/pages/goods/detail?goodsId=' + item.goodsId)">
                            <div class="card-centre">
                                <div class="card-centre-img" :style="{ 'background-image': 'url(' + item.goodsThumb + ')' }">
                                    <!-- <Image :src="item.goodsThumb" fit="cover"></Image> -->
                                    <div class="out-stock" v-if="item.serviceType === 'goods' && item.goodsNumber <= 0"></div>
                                </div>
                                <div class="card-centre-content">
                                    <div class="content-text">{{ item.goodsName }}</div>
                                    <div class="price">
                                        <!-- <div class="discountPrice">￥{{ item.price.current }}</div> -->
                                        <div class="originalPrice">￥{{ item.marketPrice }}</div>
                                        <div class="btn-base">
                                            <Checkbox v-model="item.isSelect" @on-change="onChangeCheckbox(item.goodsId, $event)"></Checkbox>
                                            <!-- <div class="btn-box" @tap.stop="onAddCart(item)">
                                                    <AtIcon value="shopping-cart" color="red"></AtIcon>
                                                </div> -->
                                        </div>
                                        <!-- <div class="btn-base" v-else>
                                                <div class="reserve-base" @tap.stop="onReserve(item)">预约</div>
                                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="empty-box" v-if="commoditys[activity_id] && commoditys[activity_id].length <= 0">
                            <comp-empty type="component" title="当前分类商品为空" prompt="正在为您筹备，敬请期待！"></comp-empty>
                        </div>
                    </div>
                </div>
            </div>

            <template #operate>
                <div class="operate-btns">
                    <Button class="operate-btn highlight" type="primary" @click="onSubmit">确定</Button>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import CompModal from "@/views/residentdatabase/components/CompModal.vue"
import RequestPage from "../../jointly/utils/page"
import Utils from "../../jointly/utils/utils"

export default {
    components: { CompModal },

    data() {
        return {
            keys: [],
            labels: [],
            labelList: null,
            place_holder: null,
            // 分类
            categorys: null,
            // 活动的分类 ID
            activity_id: "",
            // 商品列表
            commoditys: {},
        }
    },

    props: {
        required: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: "选择商品",
        },
        placeholder: {
            type: String,
            default: "",
        },
        name: String,
        value: Array,
        type: {
            type: String,
            default: "textarea",
        },
        width: {
            type: String,
            default: "300px",
        },
        // 供应商 ID
        supplierId: Number,
    },

    watch: {
        supplierId(value, old) {
            if (old) {
                // 初始化部分数据
                this.commoditysPage = {}
                this.labels = []
                this.$emit("on-change", {
                    value: null,
                    type: "ElemGoods",
                    name: this.name,
                })
            }
            // 获取分类列表
            this.getCategorys()
            // 获取商品数据
            if (this.activity_id !== "") {
                this.activity_id = ""
            } else {
                this.getCommoditys()
            }
        },

        value: {
            handler(val) {
                if (!val) return (this.labels = [])
                if (val === this.labels?.[0]) return

                this.labels = [val]
                this.keys = this.getKeys()
            },
        },

        activity_id: {
            handler() {
                this.getCommoditys()
            },
            immediate: true,
        },

        labels() {
            this.keys = this.getKeys()
        },
    },

    mounted() {
        // 占位符
        this.place_holder = this.placeholder ? this.placeholder + (this.required ? "" : "（可选）") : `选择${this.title || this.name || "选项"}${this.required ? "" : "（可选）"}`
    },

    methods: {
        onDisplayModelPage() {
            if (!this.supplierId) {
                return this.$Message.info("请选择供应商")
            }

            this.$refs.comp_model.display(this.keys)
        },

        getCommoditys() {
            if (!this.supplierId) return

            const val = this.activity_id
            const cp = this.commoditysPage || {}

            if (cp[val]) return

            const data = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                catId: val,
                supplierId: this.supplierId,
                oemCode: parent.vue.oemInfo.oemCode,
            }

            cp[val] = new RequestPage("/gateway/apps/o2o/api/o2o/goods/getOnSaleGoodsDetailByCatIdAndSupplierId", {
                data: data,
                keys: {
                    page: "currPage",
                    size: "size",
                },
                onChange: res => {
                    this.$set(
                        this.commoditys,
                        val,
                        Utils.each(res, v => {
                            // if (!v.price) {
                            // v.price = Shop.getPrice(v)
                            // }
                            if (~this.keys?.indexOf(v.goodsId)) {
                                v.isSelect = true
                            }
                        })
                    )
                },
            })

            this.commoditysPage = cp
        },

        /**
         * 获取分类列表
         */
        getCategorys() {
            this.$get("/gateway/apps/o2o/api/o2o/admin/getSupplierCategoryListBySupplierId", {
                supplierId: this.supplierId,
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    this.categorys = res.dataList
                }
            })
        },

        /**
         * 监听删除
         */
        onDeleteLabel(key, idx) {
            this.labels.splice(idx, 1)

            Utils.eachObj(this.commoditys, (k, v) => {
                Utils.each(
                    v,
                    val => {
                        val.isSelect = false
                        return "break"
                    },
                    c => c.goodsId === key
                )
            })

            this.$emit("on-change", {
                value: null,
                type: "ElemGoods",
                name: this.name,
            })
        },

        getValue() {
            return this.labels
        },

        getKeys() {
            const ks = []

            for (let i = 0, l = this.labels; i < l.length; i++) {
                ks.push(Number(l[i].key))
            }

            return ks
        },

        /**
         * 滚动到底部触发事件
         */
        onScrollToLower(evt) {
            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.commoditysPage[this.activity_id]?.load()
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },

        onChangeCheckbox(id, value) {
            if (value) {
                // 判断是否为多选，非多选就清空已选
                if (!this.multiple) {
                    Utils.eachObj(this.commoditys, (k, v) => {
                        Utils.each(
                            v,
                            val => {
                                val.isSelect = false
                            },
                            c => c.goodsId !== id
                        )
                    })

                    this.$forceUpdate()
                }
            }
        },

        onSubmit() {
            const ls = []

            Utils.eachObj(this.commoditys, (k, v) => {
                Utils.each(
                    v,
                    val => {
                        ls.push({
                            value: val.goodsName,
                            key: val.goodsId,
                        })
                    },
                    c => c.isSelect
                )
            })

            this.labels = ls

            this.$emit("on-change", {
                value: ls[0],
                type: "ElemGoods",
                name: this.name,
            })

            this.$refs.comp_model.close()
        },
    },
}
</script>

<style lang="less">
@import "../../customform/style/utils.less";

.elem-goods-box {
    width: 100%;
    font-size: 14px;
    z-index: 10;
    box-sizing: border-box;
    border-radius: 6px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #e3e3e3;
    cursor: pointer;

    &:hover {
        border-color: #b3b3b3;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .label-textarea {
        display: none;
    }

    .input-label-box {
        width: 100%;
        height: 40px;
        padding: 0 50px 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .textarea-label-box {
        width: 100%;
    }

    .label-box {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        min-height: 42px;
        padding: 0 5px;

        .hint {
            color: #c3c3c3;
            margin-left: 8px;
        }
    }

    .label-box .label-item-box {
        margin-right: 10px;
        padding: 6px 5px 6px 10px;
        display: flex;
        align-items: center;
        background: #2faaf7;
        border-radius: 4px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;
        max-width: 100%;
        word-break: break-word;
        margin: 3px;

        .value {
            font-size: 14px;
            line-height: 14px;
            color: #fff;
        }
    }

    .label-box .label-item-box:hover {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .label-box .label-item-box .delete-btn {
        position: relative;
        cursor: pointer;
        padding: 3px 8px;
        margin-left: 4px;
        width: 10px;
        height: 10px;
        display: flex;

        &::after {
            content: "";
            position: absolute;
            left: 2px;
            top: ~"calc(50% - 1px)";
            height: 1px;
            right: 2px;
            background: #fff;
            transform: rotate(45deg);
            border-radius: 1px;
        }

        &::before {
            content: "";
            position: absolute;
            left: 2px;
            top: ~"calc(50% - 1px)";
            height: 1px;
            right: 2px;
            background: #fff;
            transform: rotate(-45deg);
            border-radius: 1px;
        }
    }

    .input-box {
        flex-grow: 1;
    }
}

.category-base {
    background: rgb(248, 248, 248);
    overflow: hidden;

    .absolute(0, 0, 0, 0);

    .search-base {
        padding: 8px 10px;
        background: #14b16e;

        .search-box {
            width: 100%;
            height: 35px;
            background: rgba(255, 255, 255, 0.3);
            padding: 5px;

            .border-box;
            .radius(20px);
            .flex;
            .flex-center-all;

            .icon-box {
                font-size: 20px;
                color: #fff;

                .flex;
                .flex-center-all;
            }

            .name {
                margin-left: 10px;
                color: #fff;
            }
        }
    }

    .con-box {
        width: 100%;
        height: ~"calc(100% - 51px)";

        .flex;

        .menu-base {
            width: 105px;
            height: 100%;

            .flex-shrink;

            .menu-box {
                width: 90px;
                margin: 15px 0;
                border: 1px solid #f3f3f3;
                background: #fff;

                .radius(0 10px 10px 0);

                .item-box {
                    cursor: pointer;
                    position: relative;
                    padding: 15px 10px;
                    text-align: center;

                    .border-box;
                    .flex;
                    .flex-center-all;

                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 15px;
                        height: 1px;
                        background: #f3f3f3;
                    }

                    &:last-child::before {
                        background: initial;
                    }

                    .name {
                        position: relative;
                        font-size: 13px;
                        color: #888;
                        z-index: 10;
                    }

                    &.activity {
                        &::after {
                            content: "";
                            position: absolute;
                            top: -1px;
                            right: -7px;
                            bottom: -1px;
                            left: 0;
                            z-index: 8;
                            background: #fff;

                            .shadow(0 0 5px rgba(0, 0, 0, 0.1));
                            .radius(0 5px 5px 0);
                        }

                        .name {
                            color: #14b16e;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .commodity-box {
            position: relative;
            height: 100%;
            overflow-y: auto;
            padding-right: 15px;

            .flex-grow;

            .cart-card {
                display: flex;
                padding: 10px;
                background: #fff;
                margin: 7px 0;
                border-radius: 5px;

                .card-centre {
                    display: flex;
                    flex-grow: 1;
                    overflow: hidden;

                    .card-centre-img {
                        position: relative;
                        width: 75px;
                        height: 75px;
                        flex-shrink: 0;
                        border-radius: 5px;
                        overflow: hidden;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;

                        .img {
                            width: 100%;
                            height: 100%;
                        }

                        .out-stock {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(150, 150, 150, 0.5);

                            &::after {
                                content: "无货";
                                position: absolute;
                                top: 15px;
                                left: 15px;
                                right: 15px;
                                bottom: 15px;
                                background: rgba(62, 62, 62, 0.8);
                                border-radius: 50%;
                                color: #fff;

                                .flex;
                                .flex-center-all;
                            }
                        }
                    }

                    .card-centre-content {
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 10px;
                        margin: 2px 0;
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;
                        overflow: hidden;

                        .content-text {
                            display: -webkit-box;
                            width: 100%;
                            line-height: 20px;
                            font-size: 14px;
                            color: #333;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            word-break: break-all;
                        }

                        .price {
                            margin-top: 6px;
                            width: 100%;
                            box-sizing: border-box;
                            vertical-align: bottom;
                            display: flex;
                            flex-grow: 1;
                            align-items: flex-end;

                            .discountPrice {
                                display: inline-block;
                                font-size: 15px;
                                line-height: 20px;
                                color: rgb(255, 0, 0);

                                .ellipsis;
                            }

                            .originalPrice {
                                display: inline-block;
                                font-size: 12px !important;
                                color: lightGray;
                                text-decoration: line-through;
                                line-height: 12px;
                                margin-left: 2px;
                                transform: scale(0.8);

                                .ellipsis;
                            }

                            .btn-base {
                                margin-left: 5px;
                                font-size: 20px;

                                .flex-grow;
                                .flex;
                                .flex-content(flex-end);

                                .btn-box {
                                    display: flex;
                                }

                                .reserve-base {
                                    background: #fa9219;
                                    color: #fff;
                                    font-size: 13px;
                                    height: 21px;
                                    padding: 0 10px;
                                    border-radius: 10px;

                                    .flex-shrink;
                                    .flex;
                                    .flex-center-all;
                                }
                            }
                        }
                    }
                }
            }

            .empty-box {
                width: 100%;
                height: 100%;

                .flex;
                .flex-center-all;
            }
        }
    }
}
</style>
